import { GetStaticPaths, GetStaticProps } from "next";
import { FunctionComponent, useState, useCallback } from "react";
import {
  ArticleCardCarousel,
  Collection,
  ShopPageNav,
  ShopPageNavItem,
  ShopPageNavProps,
  ShopPageNavSwitcher,
} from "@bluebottlecoffee/design-system/components";
import { QueryParam } from "@bluebottlecoffee/design-system/components/lib/types/router";
import {
  useAnalytics,
  useCart,
  useProduct,
} from "@chordcommerce/react-autonomy";
import { ShopCardPropsWithProduct } from "@bluebottlecoffee/design-system/components/ShopCard/ShopCardTypes";
import { toArticleCardCarouselProps } from "../../../../lib/transformers/article-card-carousel";
import { QuickShopCarouselWrapper } from "../../../../components/QuickShopCarouselWrapper";
import { experiments, useExperiment } from "../../../../lib/ABTesting";
import useVariantPrice from "../../../../lib/chord/hooks/components/use-variant-price";
import { MiniCartDialog } from "../../../../components/MiniCartDialog";
import { isFeatureEnabled } from "../../../../lib/utils/is-feature-enabled";
import { filters as f } from "../../../../lib/sanity/utils";
import sanityClient from "../../../../lib/sanity-client";
import {
  Product as ProductSchema,
  ShippingCopy,
  ShippingCopy as ShippingCopySchema,
} from "../../../../lib/sanity-schema";
import {
  ShopCardPropsWithSlug,
  toShopCardProps,
} from "../../../../lib/transformers/shop-card";
import { AlgoliaShopCollectionProduct } from "../../../../lib/algolia/types";
import {
  buildSanityQuery,
  SanityQueryType,
  ShopCollectionNavQueryResult,
} from "../../../../lib/sanity/collection-queries";
import { toShopPageNavItems } from "../../../../lib/transformers/shop-page-nav";
import {
  debugPrintBuilder,
  getDefaultStaticPropsMiniCartWithSlug,
  getHomepageModules,
  getIsClientSide,
  getPrintableQueryParam,
  QuickShopCarouselAndArticleCardCarouselProps,
  quickShopCarouselAndArticleCardCarousel,
} from "../../../../lib/utils";
import { shopPage, buildAbsoluteUrl } from "../../../../lib/link-builders";
import { publicShopCollectionProductsIndex } from "../../../../lib/algolia/indices";
import { LayoutWrapper } from "../../../../components/LayoutWrapper";
import { getShopHeroModule } from "../../../../lib/utils/get-hero-module";
import localization from "../../../../localization";
import { REGION } from "../../../../lib/i18n";
import { shippingCopyQuery } from "../../../../lib/sanity/product-queries";
import renderModule from "../../../../lib/render-module";
import { CartBasePropsWithSlug } from "../../../../lib/sanity/shared";
import {
  buildTranslationGroupQuery,
  toCopyObject,
  TranslationGroupSanityType,
} from "../../../../lib/sanity/translation-group-queries";
import { ConversionCopy } from "../product/[slug]";
import useVariantAvailability from "../../../../lib/chord/hooks/components/use-variant-availability";
import { useIsClient } from "../../../../lib/hooks/use-is-client";
import { createStockRequest } from "../../../../lib/chord/transformers/product";

interface PageData extends SanityQueryType, CartBasePropsWithSlug {
  shopCards: ShopCardPropsWithSlug[];
  homePageModules: QuickShopCarouselAndArticleCardCarouselProps;
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
}

export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { props: defaultProps } =
    await getDefaultStaticPropsMiniCartWithSlug(context);

  const { lang, region, slug } = defaultProps;

  const sanityQuery = buildSanityQuery(slug, lang);

  const translationGroupQuery = buildTranslationGroupQuery(lang, "Conversion");

  const index = publicShopCollectionProductsIndex;

  const result = await Promise.all([
    sanityClient().fetch<SanityQueryType>(sanityQuery),
    index.search<AlgoliaShopCollectionProduct>("", {
      facetFilters: [[`shopCollectionSlug:${slug}`], ["forSale:true"]],
      clickAnalytics: true,
    }),
    sanityClient().fetch<ShippingCopySchema>(shippingCopyQuery),
    sanityClient()
      .fetch<TranslationGroupSanityType>(translationGroupQuery)
      .then((translations: TranslationGroupSanityType) =>
        toCopyObject<ConversionCopy>(lang, translations),
      ),
  ]);

  const sanityResult: SanityQueryType = result[0];
  const searchResults = result[1];
  const algoliaProducts: AlgoliaShopCollectionProduct[] = searchResults.hits;
  const shippingCopy = result[2];
  const copy = result[3];

  const { indexName } = publicShopCollectionProductsIndex;

  const shopCards: ShopCardPropsWithSlug[] = algoliaProducts.map(
    (algoliaProduct, idx) =>
      toShopCardProps({
        data: algoliaProduct,
        copy,
        shippingCopy,
        lang,
        region,
        props: {
          position: idx,
          queryID: searchResults.queryID,
          sourceIndexName: indexName,
        },
      }),
  );

  const homePageModules = await getHomepageModules<PageData["homePageModules"]>(
    quickShopCarouselAndArticleCardCarousel,
    lang,
    slug,
  );

  return {
    props: {
      ...defaultProps,
      ...sanityResult,
      shopCards,
      homePageModules,
      shopcardCopy: {
        copy,
        shippingCopy,
      },
    },
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const collections = await sanityClient().fetch<ProductSchema[]>(`*[
    ${f.whereType("shopCollection")} &&
    ${f.excludeDrafts}
  ]`);

  const params = localization.languages(REGION).flatMap((lang) =>
    collections.map((collection) => ({
      params: {
        region: REGION,
        lang: lang.code,
        slug: collection.slug.current,
      },
    })),
  );

  return {
    paths: params,
    fallback: false,
  };
};

const ShopCollectionPage: FunctionComponent<PageData> = ({
  flavorProfileCopy,
  navLinks,
  shopCollection,
  shopCards: shopCardsData,
  homePageModules,
  shopcardCopy,
  ...layoutWrapperProps
}) => {
  const isClient = useIsClient();
  const {
    region,
    lang,
    slug,
    giftCardFormCopy,
    cart,
    navAndCartInfoBanner,
    subscribableProducts,
    productRecs,
  } = layoutWrapperProps;
  const { altBBLogo, metaTags, modules, name, title } = shopCollection;

  const { addToCart: addToCartChord, addSubscription: subscribeProduct } =
    useCart();

  const addToCart = useCallback(
    (sku: string, quantity: number) => addToCartChord({ sku, quantity }),
    [addToCartChord],
  );

  const { createStockRequest: createStockRequestChord } = useProduct();

  const debugPrint = debugPrintBuilder(`Collection/${slug}`);
  const { trackCollectionClicked, trackProductClicked } = useAnalytics();

  const shopCards: ShopCardPropsWithProduct[] = shopCardsData.map((card) => ({
    ...card,
    subscribeProduct,
    addToCart,
    createStockRequest: (data) =>
      createStockRequest(
        data,
        card.trackShopcardData.product,
        createStockRequestChord,
      ),
    useVariantAvailability,
    useVariantPrice,
    clickTracking: () => trackProductClicked(card.trackShopcardData),
    product: isFeatureEnabled(process.env.NEXT_PUBLIC_PLP_ATC_ENABLED)
      ? card.product
      : undefined,
  })) as ShopCardPropsWithProduct[];

  const miniCartDialog = (
    <MiniCartDialog
      productRecs={productRecs}
      giftCardFormCopy={giftCardFormCopy}
      miniCartCopy={cart}
      navAndCartInfoBanner={navAndCartInfoBanner}
      region={region}
      lang={lang}
      subscribableProducts={
        isFeatureEnabled(
          process.env.NEXT_PUBLIC_CART_SUBSCRIPTION_TOGGLE_ENABLED,
        )
          ? subscribableProducts
          : []
      }
    />
  );

  // State Block
  const defaultShopPageNavItems = toShopPageNavItems({
    region,
    lang,
    navLinks,
    trackClickCollection: (
      title: string,
      collectionId: string,
      url: string,
    ) => {
      const absoluteUrl = buildAbsoluteUrl({ path: url });
      trackCollectionClicked({
        collection: {
          collectionId,
          url: absoluteUrl,
          title,
          brand: process.env.NEXT_PUBLIC_CHORD_OMS_BRAND_NAME,
        },
      });
    },
  });
  const [shopPageNavItems, setShopPageNavItems] = useState<
    Pick<ShopPageNavProps, "navItems">[]
  >(defaultShopPageNavItems);
  // END State Block

  // Use Effect Block
  const handleNavQueryNotExists = (): void => {
    debugPrint(
      "Nav query does not exist or is not in the valid format of <slug>,<display value>",
    );
    setShopPageNavItems(defaultShopPageNavItems);
  };

  /**
   * Handle any business logic related to having a `nav` query in the URL.
   *
   * ## Requirements
   * - Decorate the matching link with the provided display value
   */
  const handleNavQueryExists = ({ link, display }: ShopPageNavItem): void => {
    function initShopPageNavItems() {
      /**
       * Use the display value from the nav query as the name on the link
       * instead of the one listed in the CMS as its name;
       */
      const decoratedNavLinks = navLinks.map((navLink) => {
        const isMatch = navLink.slug === link;
        debugPrint(
          `handleNavQueryExists: comparing ${navLink.slug} to ${link}`,
        );

        if (isMatch) debugPrint(`handleNavQueryExists: Match found ${display}`);
        const overiddenNavLink: ShopCollectionNavQueryResult = {
          slug: navLink.slug,
          shopCollections: navLink.shopCollections,
          name: display,
        };

        return isMatch ? overiddenNavLink : navLink;
      });

      const decoratedShopPageNavItems = toShopPageNavItems({
        region,
        lang,
        navLinks: decoratedNavLinks,
        trackClickCollection: (
          title: string,
          collectionId: string,
          url: string,
        ) => {
          const absoluteUrl = buildAbsoluteUrl({ path: url });
          trackCollectionClicked({
            collection: {
              collectionId,
              url: absoluteUrl,
              title,
              brand: process.env.NEXT_PUBLIC_CHORD_OMS_BRAND_NAME,
            },
          });
        },
      });

      setShopPageNavItems(decoratedShopPageNavItems);
    }

    initShopPageNavItems();
  };
  // END Use Effect Block

  // Component Props Block
  const handleGetFiltersFromRoute = (filters: QueryParam): void => {
    debugPrint(
      filters.length
        ? `Getting filters from route: ${getPrintableQueryParam(filters)}`
        : "No filters in route",
    );
  };

  const handleAppendQueryFiltersToRoute = (filters: QueryParam): void => {
    debugPrint(
      filters.length
        ? `appending filters to route: ${getPrintableQueryParam(filters)}`
        : "No Filters to append to route",
    );
  };

  const handleClearFilters = (filters: QueryParam): void => {
    debugPrint(`clearing filters ${getPrintableQueryParam(filters)}`);
  };

  const shopPageNavProps: ShopPageNavProps[] = shopPageNavItems.map(
    (navItem) => ({
      ...navItem,
      onAppendQueryFiltersToRoute: handleAppendQueryFiltersToRoute,
      onClearFilters: handleClearFilters,
      onGetFiltersfromRoute: handleGetFiltersFromRoute,
      debugPrint: debugPrintBuilder("ShopPageNav"),
    }),
  );
  // END Component Props Block

  const sortByABTest = useExperiment(experiments.sortBy.id);

  return (
    isClient && (
      <LayoutWrapper
        {...{
          ...layoutWrapperProps,
          altBBLogo,
          pageTitle: title ? title[lang] : "Collection | Blue Bottle Coffee",
          metaTags,
          slideOutContent: [miniCartDialog],
          openMiniCartPanel: Boolean(miniCartDialog),
        }}
        isTransparent
      >
        {getShopHeroModule(shopCollection, region, lang)}
        {getIsClientSide() && (
          <ShopPageNavSwitcher
            linkBuilder={{ shopPage }}
            onNavQueryExists={handleNavQueryExists}
            onNavQueryNotExists={handleNavQueryNotExists}
            debugPrint={debugPrintBuilder("ShopPageNavSwitcher")}
          >
            {shopPageNavProps.map((props) => (
              <ShopPageNav {...props} />
            ))}
          </ShopPageNavSwitcher>
        )}

        {shopCards?.length > 0 && (
          <Collection
            name={name}
            shopCards={shopCards}
            mobileGridCols={1}
            ABTestSortBy={experiments.sortBy[sortByABTest]}
          />
        )}
        {modules?.length > 0 &&
          modules.map((module) =>
            renderModule({ module, region, lang, flavorProfileCopy }),
          )}

        {homePageModules.quickShopCarousel && (
          <QuickShopCarouselWrapper
            shopcardCopy={shopcardCopy}
            moduleProps={homePageModules.quickShopCarousel}
            lang={lang}
            region={region}
          />
        )}

        {homePageModules.articleCardCarousel && (
          <ArticleCardCarousel
            {...toArticleCardCarouselProps(
              homePageModules.articleCardCarousel,
              region,
              lang,
            )}
          />
        )}
      </LayoutWrapper>
    )
  );
};

export default ShopCollectionPage;
